import store from "@/store/index";

export interface AppAdministratorHealthRecordsRootListFormCreate {
  user_id: string;
  organization_id: string;
  content: {
    title: string;
    description: string;
    therapy: string;
    date_of_injury: string;
    date_of_recovery: string;
  };
}

export class AppAdministratorHealthRecordsRootListFormCreate implements AppAdministratorHealthRecordsRootListFormCreate {
  user_id: string;
  organization_id: string;
  content: {
    title: string;
    description: string;
    therapy: string;
    date_of_injury: string;
    date_of_recovery: string;
  };

  constructor() {
    this.user_id = "";
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      title: "",
      description: "",
      therapy: "",
      date_of_injury: "",
      date_of_recovery: "",
    };
  }
}