import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6e3e6595"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-health-records-root-list-form-upload-document" }
const _hoisted_2 = { class: "action" }
const _hoisted_3 = { class: "form" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Upload = _resolveComponent("Upload")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_cropper = _resolveComponent("cropper")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_button, {
        type: "primary",
        class: "el-button--grey el-button--square",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDialogVisible = true))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Upload)
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_el_dialog, {
      title: "Dodaj",
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_el_form, {
            model: _ctx.formData,
            rules: _ctx.formDataRules,
            ref: "formComponent",
            onSubmit: _withModifiers(_ctx.validateForm, ["prevent"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form_item, {
                label: "Naziv",
                prop: "content.title"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_input, {
                    modelValue: _ctx.formData.content.title,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.content.title) = $event)),
                    placeholder: "npr. Nalaz"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_el_form_item, {
                class: _normalizeClass({ 'd-none': !_ctx.formData.content.document })
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_cropper, {
                    ref: "cropper",
                    src: _ctx.formData.content.document,
                    canvas: {
                  minWidth: 300,
                  minHeight: 300,
                  maxWidth: 300,
                  maxHeight: 300,
                },
                    "default-size": {
                  width: 300,
                  height: 300
                },
                    "stencil-props": {
                  movable: true,
                  resizable: true
                }
                  }, null, 8, ["src"]),
                  _createElementVNode("input", {
                    type: "file",
                    ref: "file",
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.choosePhoto($event))),
                    accept: "image/*"
                  }, null, 544)
                ]),
                _: 1
              }, 8, ["class"]),
              _createVNode(_component_el_form_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_el_button, {
                    type: "primary",
                    class: "el-button--grey el-button--block",
                    loading: _ctx.isLoading,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$refs.file.click()))
                  }, {
                    default: _withCtx(() => [
                      (_ctx.formData.content.document)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Učitajte drugu fotografiju"))
                        : (_openBlock(), _createElementBlock("span", _hoisted_5, "Učitajte fotografiju"))
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ]),
                _: 1
              }),
              (_ctx.formData.content.document)
                ? (_openBlock(), _createBlock(_component_el_form_item, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_button, {
                        type: "primary",
                        class: "el-button--primary el-button--block",
                        "native-type": "submit",
                        loading: _ctx.isLoading,
                        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.validateForm()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("Spremi ")
                        ]),
                        _: 1
                      }, 8, ["loading"])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["model", "rules", "onSubmit"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ]))
}