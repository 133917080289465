export interface AppCommonHealthRecord {
  id: string;
  user_id: string;
  organization_id: string;
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    title: string;
    description: string;
    therapy: string;
    date_of_injury: string;
    date_of_recovery: string;
  };
  health_record_documents: any;
}

export class AppCommonHealthRecord implements AppCommonHealthRecord {
  id: string;
  user_id: string;
  organization_id: string;
  user: {
    id: string;
    content: {
      name: string;
      surname: string;
    };
  };
  content: {
    title: string;
    description: string;
    therapy: string;
    date_of_injury: string;
    date_of_recovery: string;
  };
  health_record_documents: any;

  constructor() {
    this.id = "";
    this.user_id = "";
    this.organization_id = "";
    this.user = {
      id: "",
      content: {
        name: "",
        surname: "",
      },
    };
    this.content = {
      title: "",
      description: "",
      therapy: "",
      date_of_injury: "",
      date_of_recovery: "",
    };
    this.health_record_documents = [];
  }
}