
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store/index";

// Vendors
import moment from "moment";

// Models
import {AppAdministratorHealthRecordsRootListIndex} from "@/models/app/administrator/health-records/root/list/index";
import {AppCommonUser} from "@/models/app/common/User";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import AppAdministratorHealthRecordsRootListFormCreateIndexVue from "@/views/app/administrator/health-records/root/list/form/create.vue";
import AppAdministratorHealthRecordsRootListFormUpdateIndexVue from "@/views/app/administrator/health-records/root/list/form/update.vue";
import AppAdministratorHealthRecordsRootListFormRemoveIndexVue from "@/views/app/administrator/health-records/root/list/form/remove.vue";
import AppAdministratorHealthRecordsRootListFormLookupDocumentIndexVue from "@/views/app/administrator/health-records/root/list/form/lookup-document.vue";
import AppAdministratorHealthRecordsRootListFormUploadDocumentIndexVue from "@/views/app/administrator/health-records/root/list/form/upload-document.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  props: [
    "players",
  ],
  components: {
    ElRow,
    ElCol,
    AppAdministratorHealthRecordsRootListFormCreateIndexVue,
    AppAdministratorHealthRecordsRootListFormUpdateIndexVue,
    AppAdministratorHealthRecordsRootListFormRemoveIndexVue,
    AppAdministratorHealthRecordsRootListFormLookupDocumentIndexVue,
    AppAdministratorHealthRecordsRootListFormUploadDocumentIndexVue,
  },
})
export default class AppAdministratorHealthRecordsRootListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  players: Array<AppCommonUser> = [];
  healthRecords: Array<AppAdministratorHealthRecordsRootListIndex> = [];

  async getHealthRecords(formData: any = null): Promise<void> {
    this.isLoading = true;

    if (formData) {
      this.formData = formData;
    }

    await getRequest({
      uri: `/common/health-records`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.healthRecords = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    await this.getHealthRecords();
  }
}
