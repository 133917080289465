
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppAdministratorHealthRecordsRootListFormUpdate} from "@/models/app/administrator/health-records/root/list/form/update";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElDialog,
  ElForm,
  ElFormItem,
  ElInput,
  ElDatePicker,
  ElButton,
} from "element-plus";
import {Edit} from "@element-plus/icons-vue";

// Services
import {postRequest} from "@/services/api/request";

@Options({
  props: [
    "healthRecord",
  ],
  components: {
    ElDialog,
    ElForm,
    ElFormItem,
    ElInput,
    ElDatePicker,
    ElButton,
    Edit,
  },
})
export default class AppAdministratorHealthRecordsRootListFormUpdateIndexVue extends Vue {
  isLoading: boolean | null = false;
  isDialogVisible: boolean | null = false;

  healthRecord: AppAdministratorHealthRecordsRootListFormUpdate = new AppAdministratorHealthRecordsRootListFormUpdate();

  formData: AppAdministratorHealthRecordsRootListFormUpdate = new AppAdministratorHealthRecordsRootListFormUpdate();
  formDataRules = {
    content: {
      title: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
      date_of_injury: [
        {
          required: true,
          message: "Polje nije popunjeno",
          trigger: "blur",
        },
      ],
    },
  };

  $refs!: {
    formComponent: HTMLFormElement;
  };

  validateForm(): void {
    this.$refs.formComponent.validate((response: any) => {
      if (response) {
        this.submitForm();
      }
    });
  }

  async submitForm(): Promise<void> {
    this.isLoading = true;
    await postRequest({
      uri: "/common/health-record/update",
      formData: this.formData,
      isProtected: true,
      isSuccessNotificationVisible: true,
      isErrorNotificationVisible: true,
    }).then((r: ResponseIndex) => {
      if (r.status === "success") {
        this.$emit('getHealthRecords');
        this.isDialogVisible = false;
        this.resetFormData();
      }
    });
    this.isLoading = false;
  }

  setFormData(): void {
    this.formData = this.healthRecord;
  }

  resetFormData(): void {
    this.formData = new AppAdministratorHealthRecordsRootListFormUpdate();
  }

  updated(): void {
    this.setFormData();
  }
}
